<template>
  <div class="accountAdd">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      @update="update"
    >
      <!-- 解决浏览器自动填充账号密码的问题 -->
      <input type="text" autocomplete="off" style="display: none" />
      <input type="password" style="position: fixed; bottom: -99999px" />
      <el-form-item
        label="姓名"
        :rules="[{ required: true, message: '请输入姓名', trigger: 'blur' }]"
        prop="userName"
      >
        <v-input placeholder="请输入姓名" v-model="form.userName"></v-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'blur' },
        ]"
        prop="mobileNum"
      >
        <div class="form-item">
          <v-input
            placeholder="请输入手机号"
            v-model="form.mobileNum"
            :maxlength="11"
            :disabled="isEdit"
          ></v-input>
          <v-button
            style="margin-left: 10px"
            text="编辑"
            v-if="form.id"
            @click="handelClick"
          ></v-button>
        </div>
      </el-form-item>
      <el-form-item
        v-if="!isEdit"
        label="验证码"
        :rules="[
          {
            required: true,
            message: '请输入验证码',
            trigger: 'blur',
          },
        ]"
        prop="code"
      >
        <v-input
          class="newTe"
          placeholder="请输入验证码"
          v-model="form.code"
          :maxlength="6"
        ></v-input>
        <v-button text="获取验证码" v-if="isCode.isGetCode" @click="getCode" />
        <v-button
          :text="`${isCode.num}` + 's'"
          v-if="isCode.isTime"
          type="info"
        />
        <v-button text="重新获取" v-if="isCode.isTwoGetCode" @click="getCode" />
      </el-form-item>

      <el-form-item
        label="账号名"
        :rules="[{ required: true, message: '请输入账号名', trigger: 'blur' }]"
        prop="loginName"
      >
        <v-input placeholder="请输入账号名" v-model="form.loginName"></v-input>
      </el-form-item>
      <el-form-item
        label="密码"
        :rules="[
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            validator: validateNewPassword,
          },
        ]"
        prop="loginPwd"
      >
        <v-input
          placeholder="请输入密码"
          type="password"
          v-model="form.loginPwd"
        ></v-input>
      </el-form-item>
      <el-form-item
        label="密码确认"
        :rules="[
          {
            required: true,
            message: '请输入确认密码',
            trigger: 'change',
          },
          {
            validator: validateNewPasswordRepeat,
            trigger: 'change',
          },
        ]"
        prop="newPasswordRepeat"
      >
        <v-input
          placeholder="请确认密码"
          type="password"
          v-model="form.newPasswordRepeat"
        ></v-input>
      </el-form-item>

      <el-form-item
        label="所属管理组"
        :rules="[
          { required: true, message: '请选择所属管理组', trigger: 'change' },
        ]"
        prop="manageGroupId"
      >
        <select-tree
          v-loading="treeLoading"
          :value.sync="form.manageGroupId"
          :data.sync="treeSelectManageData"
          placeholder="所属管理组"
          :treeData="treeManageData"
          :treeProps="treeManageProps"
          :highlightCurrent="true"
          :filterNodeMethod="filterNodeMethod"
          :expand-on-click-node="false"
        />
      </el-form-item>

      <el-form-item
        label="绑定角色"
        prop="roleId"
        :rules="[{ required: true, message: '请绑定角色', trigger: 'change' }]"
      >
        <select-tree
          :loading="treeRoleLoading"
          :value.sync="form.roleId"
          :data.sync="treeSelectData"
          placeholder="所属角色"
          :treeData="treeData"
          :treeProps="treeProps"
          :highlightCurrent="true"
          :filterNodeMethod="filterNodeMethod"
          :expand-on-click-node="false"
        />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { setAESCbcEncrypt } from "@/utils/common";
import { mobileRegular } from "@/utils/regular";
import selectTree from "@/components/bussiness/select-tree/select-tree.vue";
import {
  addAccountUrl,
  accountDetailUrl,
  getManageGroupTreeUrl,
  roleListUrl,
  bindRoleUrl,
  getFreeVerificationCode,
  phoneEdit,
} from "./api.js";

export default {
  name: "accountAdd",
  components: {
    "select-tree": selectTree,
  },
  data() {
    return {
      id: "",
      isCode: {
        isGetCode: true,
        isTime: false,
        isTwoGetCode: false,
        num: 60,
        timer: null,
      },
      form: {
        id: "", // 数据唯一值
        userName: "", // 姓名（昵称）
        mobileNum: "", // 手机号
        code: "", //验证码
        loginName: "", // 登陆账户
        originPwd: "", //初始密码
        loginPwd: "", // 登陆密码
        newPasswordRepeat: "", // 确认密码
        manageGroupId: "", // 管理组id
        roleId: "", // 角色id
        userSource: 3, // 固定
        isRegister: 4, // 固定
      },
      /* 管理组树 */
      treeSelectManageData: {}, // 所选树节点的数据
      treeManageData: [], // 数据节点选项数据
      treeLoading: false,
      treeManageProps: {
        id: "id", // 必须
        parentId: "parentId",
        children: "children", // 必须
        label: "name", // 必须
      },
      /* 角色树 */
      treeData: [],
      treeSelectData: {},
      treeRoleLoading: false,
      treeProps: {
        id: "id", // 必须
        parentId: "parentId",
        children: "children", // 必须
        label: "roleName", // 必须
      },

      submitConfig: {
        queryUrl: accountDetailUrl,
        queryMethod: "post",
        submitUrl: addAccountUrl,
      },
      onOff: false, // 是否编辑的第一次进入
      isEdit: false,
    };
  },
  computed: {
    manageGroupId() {
      return this.form.manageGroupId;
    },
  },
  beforeDestroy() {
    clearInterval(this.isCode.timer);
  },
  watch: {
    manageGroupId(val) {
      if (!this.onOff) {
        this.form.roleId = "";
        console.log(this.form, "this.form");
        this.treeSelectData = {};
        this.treeData = [];
      }
      if (val) {
        this.getRoleTreeList();
      }
    },
  },
  mounted() {
    this.getTreeList();
    const { id } = this.$route.query;
    if (id !== undefined) {
      this.isEdit = true;
      this.$refs.formPanel.getData({ userId: id });
    }
    this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    handelClick() {
      this.form.mobileNum = "";
      this.isEdit = false;
    },
    codeInit() {
      this.isCode.isGetCode = false;
      this.isCode.isTime = false;
      this.isCode.isTwoGetCode = true;
      this.isCode.num = 60;
      this.isCode.timer = null;
    },
    async getCode() {
      if (!this.form.mobileNum) {
        this.$message.info("请填写手机号");
        return;
      }
      this.isCode.isGetCode = false;
      this.isCode.isTime = true;
      this.isCode.isTwoGetCode = false;
      if (this.isCode.timer) {
        clearInterval(this.isCode.timer);
      }
      this.isCode.timer = setInterval(() => {
        if (this.isCode.num <= 0) {
          clearInterval(this.isCode.timer);
          this.codeInit();
        } else {
          this.isCode.num--;
        }
      }, 1000);
      let params = {
        mobileNum: this.form.mobileNum,
        tenantId: this.$store.state.app.userInfo.tenantId,
      };
      let res = await this.$axios.post(`${getFreeVerificationCode}`, params);
      return res;
    },
    submitBefore() {
      if (this.onOff) {
        if (this.form.code) {
          const params = {
            id: this.form.id,
            mobileNum: setAESCbcEncrypt(this.form.mobileNum),
            code: this.form.code,
          };
          this.$axios.post(phoneEdit, params).then((res) => {
            if (res.code == 200) {
              this.isEdit = true;
            }
          });
        }
        if (this.form.originPwd == this.form.loginPwd) {
          return {
            id: this.form.id, // 数据唯一值
            userName: this.form.userName, // 姓名（昵称）
            // mobileNum: this.form.mobileNum, // 手机号
            // code: this.form.code,
            loginName: this.form.loginName, // 登陆账户
            manageGroupId: this.form.manageGroupId, // 管理组id
            roleId: this.form.roleId, // 角色id
            userSource: 3,
            isRegister: 4,
          };
        } else {
          return true;
        }
      } else {
        this.form.mobileNum = setAESCbcEncrypt(this.form.mobileNum);
        return true;
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
      this.form.originPwd = this.form.loginPwd;
      this.form.newPasswordRepeat = this.form.loginPwd;
      this.form.roleId = data.roleId;
    },
    /* 验证及查询方法 */
    mobileValid(value) {
      return value.replace(/\D/g, "");
    },
    validateNewPassword(rule, value, callback) {
      if (!this.form.id) {
        if (
          !value.match(/^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[^a-zA-Z0-9]).{8,}$/)
        ) {
          callback(new Error("需要至少8位包含英文、字符、数字的密码"));
        } else {
          callback();
        }
      }
    },
    validateNewPasswordRepeat(rule, value, callback) {
      if (value !== this.form.loginPwd) {
        callback(new Error("两次密码必须一致"));
      } else {
        callback();
      }
    },
    validatePhone(rule, value, callback) {
      let regExp = mobileRegular;
      if (!regExp.test(value)) {
        callback(new Error("您填写手机号码格式不正确"));
      } else {
        callback();
      }
    },
    filterNodeMethod(value, data) {
      // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true;
      let result =
        data[this.treeProps.label] === undefined
          ? data[this.treeManageProps.label]
          : data[this.treeProps.label];
      return result.indexOf(value) !== -1;
    },

    // 获取树节点请求
    async getTreeList() {
      // 获取树节点数据
      this.treeLoading = true;
      this.treeManageData = [];
      const res = await this.$axios.get(getManageGroupTreeUrl);
      if (res.code == 200 && res.success) {
        this.treeManageData = res.data || [];
      }
      this.treeLoading = false;
    },
    // 获取角色树节点请求
    async getRoleTreeList() {
      this.treeRoleLoading = true;
      this.treeData = [];
      const res = await this.$axios.get(roleListUrl, {
        params: {
          manageGroupId: this.manageGroupId,
        },
      });
      if (res.code == 200 && res.success) {
        this.treeData = res.data || [];
      }
      this.treeRoleLoading = false;
    },
    // 请求成功后调接口--作用未知
    async submitSuccess(data) {
      // let params = {
      //   userIds: data,
      //   roleIds: this.form.roleIds,
      // };
      // let res = await this.$axios.post(bindRoleUrl, params);
      // if (res.code == 200 && res.success) {
      //   return true;
      // } else {
      //   return false;
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.accountAdd {
  height: 100%;
  .form-item {
    display: flex;
  }
  .newTe {
    margin-right: 6px;
  }
}
</style>
