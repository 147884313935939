//获取园区列表
const getCommunityListUrl = `/gateway/hc-space/space/community`;
//获取账号列表
const getAccountListUrl = `/gateway/blade-user/user/list`;
//删除账号
const deleteAccountUrl = `/gateway/blade-user/user/remove`;
//重置账号
const setAccountUrl = `/gateway/blade-user/user/reset-password`;
//更改账号状态
const setAccountStatusUrl = `/gateway/blade-user/user/submit`;
//新增账号
const addAccountUrl = `/gateway/blade-user/user/submit`;
//账号详情
const accountDetailUrl = `/gateway/blade-user/user/detail`;
// 获取管理组
const getManageGroupTreeUrl = `/gateway/hc-space/space/getManageGroupTree`;
//角色列表
const roleListUrl = `/gateway/blade-system/role/list`;
//角色绑定
const bindRoleUrl = `/gateway/blade-user/user/grant`;
//手机号编辑
const phoneEdit = `/gateway/blade-user/user//phoneEdit`;
//获取验证码
const getFreeVerificationCode = `/gateway/blade-user/user/getFreeVerificationCode`;

export {
  getCommunityListUrl,
  getAccountListUrl,
  deleteAccountUrl,
  setAccountUrl,
  setAccountStatusUrl,
  addAccountUrl,
  accountDetailUrl,
  getManageGroupTreeUrl,
  roleListUrl,
  bindRoleUrl,
  getFreeVerificationCode,
  phoneEdit
};
