var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"accountAdd"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('input',{staticStyle:{"display":"none"},attrs:{"type":"text","autocomplete":"off"}}),_c('input',{staticStyle:{"position":"fixed","bottom":"-99999px"},attrs:{"type":"password"}}),_c('el-form-item',{attrs:{"label":"姓名","rules":[{ required: true, message: '请输入姓名', trigger: 'blur' }],"prop":"userName"}},[_c('v-input',{attrs:{"placeholder":"请输入姓名"},model:{value:(_vm.form.userName),callback:function ($$v) {_vm.$set(_vm.form, "userName", $$v)},expression:"form.userName"}})],1),_c('el-form-item',{attrs:{"label":"手机号","rules":[
        { required: true, message: '请输入手机号', trigger: 'change' },
        { validator: _vm.validatePhone, trigger: 'blur' },
      ],"prop":"mobileNum"}},[_c('div',{staticClass:"form-item"},[_c('v-input',{attrs:{"placeholder":"请输入手机号","maxlength":11,"disabled":_vm.isEdit},model:{value:(_vm.form.mobileNum),callback:function ($$v) {_vm.$set(_vm.form, "mobileNum", $$v)},expression:"form.mobileNum"}}),(_vm.form.id)?_c('v-button',{staticStyle:{"margin-left":"10px"},attrs:{"text":"编辑"},on:{"click":_vm.handelClick}}):_vm._e()],1)]),(!_vm.isEdit)?_c('el-form-item',{attrs:{"label":"验证码","rules":[
        {
          required: true,
          message: '请输入验证码',
          trigger: 'blur',
        },
      ],"prop":"code"}},[_c('v-input',{staticClass:"newTe",attrs:{"placeholder":"请输入验证码","maxlength":6},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),(_vm.isCode.isGetCode)?_c('v-button',{attrs:{"text":"获取验证码"},on:{"click":_vm.getCode}}):_vm._e(),(_vm.isCode.isTime)?_c('v-button',{attrs:{"text":`${_vm.isCode.num}` + 's',"type":"info"}}):_vm._e(),(_vm.isCode.isTwoGetCode)?_c('v-button',{attrs:{"text":"重新获取"},on:{"click":_vm.getCode}}):_vm._e()],1):_vm._e(),_c('el-form-item',{attrs:{"label":"账号名","rules":[{ required: true, message: '请输入账号名', trigger: 'blur' }],"prop":"loginName"}},[_c('v-input',{attrs:{"placeholder":"请输入账号名"},model:{value:(_vm.form.loginName),callback:function ($$v) {_vm.$set(_vm.form, "loginName", $$v)},expression:"form.loginName"}})],1),_c('el-form-item',{attrs:{"label":"密码","rules":[
        { required: true, message: '请输入密码', trigger: 'blur' },
        {
          validator: _vm.validateNewPassword,
        },
      ],"prop":"loginPwd"}},[_c('v-input',{attrs:{"placeholder":"请输入密码","type":"password"},model:{value:(_vm.form.loginPwd),callback:function ($$v) {_vm.$set(_vm.form, "loginPwd", $$v)},expression:"form.loginPwd"}})],1),_c('el-form-item',{attrs:{"label":"密码确认","rules":[
        {
          required: true,
          message: '请输入确认密码',
          trigger: 'change',
        },
        {
          validator: _vm.validateNewPasswordRepeat,
          trigger: 'change',
        },
      ],"prop":"newPasswordRepeat"}},[_c('v-input',{attrs:{"placeholder":"请确认密码","type":"password"},model:{value:(_vm.form.newPasswordRepeat),callback:function ($$v) {_vm.$set(_vm.form, "newPasswordRepeat", $$v)},expression:"form.newPasswordRepeat"}})],1),_c('el-form-item',{attrs:{"label":"所属管理组","rules":[
        { required: true, message: '请选择所属管理组', trigger: 'change' },
      ],"prop":"manageGroupId"}},[_c('select-tree',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.treeLoading),expression:"treeLoading"}],attrs:{"value":_vm.form.manageGroupId,"data":_vm.treeSelectManageData,"placeholder":"所属管理组","treeData":_vm.treeManageData,"treeProps":_vm.treeManageProps,"highlightCurrent":true,"filterNodeMethod":_vm.filterNodeMethod,"expand-on-click-node":false},on:{"update:value":function($event){return _vm.$set(_vm.form, "manageGroupId", $event)},"update:data":function($event){_vm.treeSelectManageData=$event}}})],1),_c('el-form-item',{attrs:{"label":"绑定角色","prop":"roleId","rules":[{ required: true, message: '请绑定角色', trigger: 'change' }]}},[_c('select-tree',{attrs:{"loading":_vm.treeRoleLoading,"value":_vm.form.roleId,"data":_vm.treeSelectData,"placeholder":"所属角色","treeData":_vm.treeData,"treeProps":_vm.treeProps,"highlightCurrent":true,"filterNodeMethod":_vm.filterNodeMethod,"expand-on-click-node":false},on:{"update:value":function($event){return _vm.$set(_vm.form, "roleId", $event)},"update:data":function($event){_vm.treeSelectData=$event}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }